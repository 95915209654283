import service from '@/utils/request';
export function getOrdinaryDeptList(params) {
  return service({
    url: '/sysDept/ordinaryDeptList',
    method: 'get',
    params: params
  });
}

// @Tags SysDept
// @Summary 创建SysDept
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDept true "创建SysDept"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysDept/createSysDept [post]
export var createSysDept = function createSysDept(data) {
  return service({
    url: "/sysDept/createSysDept",
    method: 'post',
    data: data
  });
};

// @Tags SysDept
// @Summary 删除SysDept
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDept true "删除SysDept"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysDept/deleteSysDept [delete]
export var deleteSysDept = function deleteSysDept(data) {
  return service({
    url: "/sysDept/deleteSysDept",
    method: 'delete',
    data: data
  });
};

// @Tags SysDept
// @Summary 删除SysDept
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysDept"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysDept/deleteSysDept [delete]
export var deleteSysDeptByIds = function deleteSysDeptByIds(data) {
  return service({
    url: "/sysDept/deleteSysDeptByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysDept
// @Summary 更新SysDept
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDept true "更新SysDept"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysDept/updateSysDept [put]
export var updateSysDept = function updateSysDept(data) {
  return service({
    url: "/sysDept/updateSysDept",
    method: 'put',
    data: data
  });
};

// @Tags SysDept
// @Summary 用id查询SysDept
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysDept true "用id查询SysDept"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysDept/findSysDept [get]
export var findSysDept = function findSysDept(params) {
  return service({
    url: "/sysDept/findSysDept",
    method: 'get',
    params: params
  });
};

// @Tags SysDept
// @Summary 分页获取SysDept列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysDept列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysDept/getSysDeptList [get]
export var getSysDeptList = function getSysDeptList(params) {
  return service({
    url: "/sysDept/getSysDeptList",
    method: 'get',
    params: params
  });
};
export var getDeptTreeList = function getDeptTreeList(params) {
  return service({
    url: "/sysDept/getDeptTreeList",
    method: 'get',
    params: params
  });
};